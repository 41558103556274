new WOW({ offset: 70 }).init();

function loadSliders() {
	$('.home-gallery__inner').slick({
		centerMode: true,
		centerPadding: '0',
		slidesToShow: 6,
		autoplay: true,
		arrows: false,
		autoplaySpeed: 5000,
		speed: 1000,
		responsive: [
			{
				breakpoint: 900,
				settings: {
					arrows: false,
					centerMode: false,
					//centerPadding: '40px',
					slidesToShow: 4
				}
			},
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 2
				}
			}

		]
	});

	$('.testimonials .carousel-inner').slick({
		centerMode: true,
		centerPadding: '0',
		slidesToShow: 3,
		autoplay: true,
		arrows: false,
		autoplaySpeed: 5000,
		speed: 1000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 2
				}
			},
			{
				breakpoint: 400,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 1
				}
			}

		]
	});
}

var sliderScript = document.getElementById("deferredSlickScript");

if (sliderScript) {
	sliderScript.addEventListener('load', function () {
		loadSliders();
	});
}

$(document).ready(function () {

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	function fixHeights() {
		$(".RotatorTestimonialContent").sameHeight();
	}

	setTimeout(fixHeights, 500);

	$(window).on("resize", function () {
		setTimeout(fixHeights, 500);
	});
	
});

